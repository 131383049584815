import React, { useState } from "react";
import cornerLogoBlack from "../static/icons/cornerLogoOnly.svg";
import arrowRight from "../static/icons/arrowRight.svg";
import lineRight from "../static/lineRight.svg";
import lineLeft from "../static/lineLeft.svg";
import { Link } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import { ModalWait } from "../components/ModalWait/ModalWait";
import planetCrypto from "../static/video/waitListPlanet.mp4";
import { useTranslation } from "react-i18next";

const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;
const captchaKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

export const WaitList = () => {
  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState(0);

  const { t } = useTranslation();

  const handleClose = () => {
    setShowModal(false);
    setStep(0);
  };

  return (
    <>
      <div
        className="h-100 container mx-auto d-flex flex-column align-items-center position-relative overflow-hidden"
        style={{ minHeight: "100vh" }}
      >
        <Navbar
          bg="transparent"
          expand="lg"
          className="wait__nav w-100 d-flex justify-content-between align-items-center"
        >
          <Link
            to="/"
            className="wait__logo text-decoration-none text-dark fw-bold d-flex align-items-center"
          >
            <img
              src="https://assetscornerswap.fra1.cdn.digitaloceanspaces.com/cornerLogoOnly.svg"
              alt="cornerSwapLogo"
              style={{
                height: "100%",
              }}
            />
            <span
              className="fs-6"
              style={{
                fontWeight: "800",
              }}
            >
              CORNERSWAP
            </span>
          </Link>

          <button
            className="d-none d-md-flex text-decoration-none bg-dark text-light fs-5 align-items-center px-5 rounded-3 mt-4 mt-sm-0 me-auto me-md-1 mx-auto mx-md-0"
            style={{
              boxShadow: "4px 5px 10px rgba(0, 0, 0, 0.5)",
              height: "56px",
            }}
            onClick={() => setShowModal(true)}
          >
            Join the waitlist
          </button>
        </Navbar>

        <p
          className="text-center col-9 col-md-10 p-0 m-0 mt-3 mt-md-0 mx-auto lh-sm wait__title--less"
          style={{
            fontWeight: "800",
            letterSpacing: "-.2rem",
          }}
        >
          WELCOME TO THE EXCHANGE CORNER IN THE WORLD OF WEB 3
        </p>

        <p className="wait__sub text-center col-12 col-md-7 p-0 m-0 mx-auto mt-5 mt-md-3">
          "Empower your financial future through the cryptographic revolution!"
        </p>

        <div className="mt-5 mt-md-3">
          <video
            autoPlay
            loop
            muted
            className="mx-auto text-center position-relative"
            style={{
              maxWidth: "760px",
              width: "100%",
              maxhHight: "440px",
              hight: "100%",
            }}
          >
            <source src={planetCrypto} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <button
          className="text-decoration-none text-dark border-0 d-flex fs-4 fw-semibold align-items-center px-4 rounded-3 my-5 my-md-4"
          style={{
            boxShadow: "4px 5px 10px rgba(0, 0, 0, 0.2)",
            height: "70px",
            backgroundColor: "#AEFC75",
            letterSpacing: "-.03rem",
          }}
          onClick={() => setShowModal(true)}
        >
          <span>Join the waitlist</span>

          <div className="d-none d-md-block">
            {/* <img
              src={arrowRight}
              alt="arrowRight"
              style={{
                height: "40px",
              }}
            /> */}

            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0H40V40H0V0Z" fill="white" fillOpacity="0.01" />
              <path
                d="M15.8333 10L25.8333 20L15.8333 30"
                stroke="black"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </button>

        <div
          className="d-none d-md-block position-absolute bottom-0"
          style={{
            zIndex: "-1",
            right: "-70px",
            // right: "0px",
            // paddingLeft: "70px",
          }}
        >
          <svg
            style={{
              maxHeight: "25vh",
              height: "100%",
              width: "100%",
            }}
            viewBox="0 0 721 585"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="720.685"
              y1="584.388"
              x2="0.685018"
              y2="0.38834"
              stroke="black"
              strokeDasharray="5 5"
            />
          </svg>
        </div>

        <div
          className="d-none d-md-block position-absolute bottom-0"
          style={{
            zIndex: "-1",
            left: "-70px",
          }}
        >
          <svg
            style={{
              maxHeight: "25vh",
              height: "100%",
              width: "100%",
            }}
            viewBox="0 0 721 585"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="720.315"
              y1="0.388321"
              x2="0.314961"
              y2="584.388"
              stroke="black"
              strokeDasharray="5 5"
            />
          </svg>
        </div>

        <ModalWait
          showModal={showModal}
          handleClose={handleClose}
          step={step}
          setStep={setStep}
        />
      </div>

      <div className="container my-5 pt-5 px-sm-5 border-top border-grey">
        <Nav className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
          <Link
            to="/policy"
            className="m-0 p-0 text-decoration-underline"
            style={{ color: "black" }}
          >
            {t("footer.links.policy")}
          </Link>
          {/* <Link
            to="/security"
            className="m-0 p-0 text-decoration-underline"
            style={{ color: "black" }}
          >
            {t("footer.links.security")}
          </Link> */}
          {/* <Link
            to="/amlkyc"
            className="m-0 p-0 text-decoration-underline"
            style={{ color: "black" }}
          >
            {t("footer.links.amlkyc")}
          </Link> */}
          <Link
            to="/conditions"
            className="m-0 p-0 text-decoration-underline"
            style={{ color: "black" }}
          >
            {t("footer.links.conditions")}
          </Link>
        </Nav>
      </div>
    </>
  );
};
