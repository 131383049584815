import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ICurrencyExchange {
  maj: string;
  min: string;
  quote: string;
  name: string;
  symbol: string;
}

export interface IOrderSlice {
  listCryptocurrency: any;

  inputAmountBuy: string;
  inputCurrencyBuy: ICurrencyExchange;
  outputAmountBuy: string;
  outputCurrencyBuy: string;

  inputAmountSell: string;
  inputCurrencySell: string;
  outputAmountSell: string;
  outputCurrencySell: ICurrencyExchange;

  paymentType: "ApplePay" | "GooglePay" | "Card" | "BankTransfer" | "";
  operationType: "Buy" | "Sell";
}

const initialState = {
  listCryptocurrency: [],

  inputAmountBuy: "500",
  inputCurrencyBuy: {
    maj: "EUR",
    min: "eur",
    quote: "ZEUR",
    name: "Euro",
    symbol: "€",
  },
  outputAmountBuy: "",
  outputCurrencyBuy: "ETH",

  inputAmountSell: "0.0952",
  inputCurrencySell: "BTC",
  outputAmountSell: "",
  outputCurrencySell: {
    maj: "EUR",
    min: "eur",
    quote: "ZEUR",
    name: "Euro",
    symbol: "€",
  },

  paymentType: "",
  operationType: "Buy",
};

type ListCryptocurrency = PayloadAction<any>;

type InputAmountBuy = PayloadAction<string>;
type InputCurrencyBuy = PayloadAction<ICurrencyExchange>;
type OutputAmountBuy = PayloadAction<string>;
type OutputCurrencyBuy = PayloadAction<string>;

type InputAmountSell = PayloadAction<string>;
type InputCurrencySell = PayloadAction<string>;
type OutputAmountSell = PayloadAction<string>;
type OutputCurrencySell = PayloadAction<ICurrencyExchange>;

type PaymentType = PayloadAction<
"ApplePay" | "GooglePay" | "Card" | "BankTransfer" | ""
>;
type OperationType = PayloadAction<"Buy" | "Sell">;

const orderSlice = createSlice({
  name: "langIcon",
  initialState,
  reducers: {
    setListCryptocurrency: (state, action: ListCryptocurrency) => {
      state.listCryptocurrency = action.payload;
    },
    setInputAmountBuy: (state, action: InputAmountBuy) => {
      state.inputAmountBuy = action.payload;
    },
    setInputCurrencyBuy: (state, action: InputCurrencyBuy) => {
      state.inputCurrencyBuy = action.payload;
    },
    setOutputAmountBuy: (state, action: OutputAmountBuy) => {
      state.outputAmountBuy = action.payload;
    },
    setOutputCurrencyBuy: (state, action: OutputCurrencyBuy) => {
      state.outputCurrencyBuy = action.payload;
    },
    setInputAmountSell: (state, action: InputAmountSell) => {
      state.inputAmountSell = action.payload;
    },
    setInputCurrencySell: (state, action: InputCurrencySell) => {
      state.inputCurrencySell = action.payload;
    },
    setOutputAmountSell: (state, action: OutputAmountSell) => {
      state.outputAmountSell = action.payload;
    },
    setOutputCurrencySell: (state, action: OutputCurrencySell) => {
      state.outputCurrencySell = action.payload;
    },
    setPaymentType: (state, action: PaymentType) => {
      state.paymentType = action.payload;
    },
    setOperationType: (state, action: OperationType) => {
      state.operationType = action.payload;
    },
  },
});

export const {
  setListCryptocurrency,
  setInputAmountBuy,
  setInputCurrencyBuy,
  setOutputAmountBuy,
  setOutputCurrencyBuy,
  setInputAmountSell,
  setInputCurrencySell,
  setOutputAmountSell,
  setOutputCurrencySell,
  setPaymentType,
  setOperationType,
} = orderSlice.actions;

export const orderReducer = orderSlice.reducer;
