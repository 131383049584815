import React, { useEffect, useState } from "react";
import arrowRight from "../../static/icons/arrowRight.svg";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;
const captchaKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

interface IStep0 {
  setStep: (step: number) => void;
}

export const Step0: React.FC<IStep0> = ({ setStep }) => {
  const [loader, setLoader] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");

  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
  });

  interface IFormValues {
    name: string;
    email: string;
  }

  const handleSubmit = (values: IFormValues) => {
    setLoader(true);

    const objectToPost = {
      email: values.email,
      name: values.name,
      captcha: captchaValue,
    };

    axios
      .post(`${backendUrl}/captcha`, objectToPost)
      .then(function (response) {
        console.log("Success!");
      })
      .catch(function (error) {
        console.log("error :", error);
      });

    setStep(1);
  };

  function onChange(value: any) {
    setCaptchaValue(value);
  }

  return (
    <div
      className="container h-100 d-flex flex-column"
      style={{
        background: "#fff",
        height: "max-content",
        maxWidth: "100vw",
        overflowY: "scroll",
      }}
    >
      <p
        className="text-center col-9 col-md-8 mx-auto m-0 mt-4 lh-sm wait__title"
        style={{
          fontWeight: "800",
          letterSpacing: "-3px",
          maxWidth: "100vw",
        }}
      >
        STAY INFORMED ABOUT THE LAUNCH !
      </p>

      <p
        className="wait__sub text-center col-md-8 col-lg-5 mx-auto mt-5"
        style={{ fontWeight: "600" }}
      >
        Enter your name and your email to join our community
      </p>

      <div className="col-12 col-md-8 mx-auto mt-5">
        <Formik
          initialValues={{ name: "", email: "" }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, handleChange }) => (
            <Form className="d-flex flex-column">
              <div className="row d-flex flex-column flex-md-row justify-content-between">
                <div className="col-12 col-md-6">
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Your name"
                    className={`w-100 wait__form-input fs-5 ps-4 ms-md-1 ${errors.name && touched.name ? "border-danger" : "border-0"}`}
                    style={{
                      background: "#D9D9D9",
                      borderRadius: "40px",
                    }}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Your email"
                    className={`w-100 wait__form-input fs-5 ps-4 mt-4 mt-md-0 ${errors.email && touched.email ? "border-danger" : "border-0"}`}
                    style={{
                      background: "#D9D9D9",
                      borderRadius: "40px",
                    }}
                  />
                </div>
              </div>

              <div className="mt-3 ps-2">
                <ReCAPTCHA sitekey={captchaKey} onChange={onChange} />
              </div>

              <button
                type="submit"
                className="w-100 wait__form-input text-dark border-0 d-flex fw-semibold justify-content-center align-items-center my-5"
                style={{
                  backgroundColor: captchaValue
                    ? "#AEFC75"
                    : "rgba(220, 220, 220, 1)",
                  letterSpacing: "-.03rem",
                  borderRadius: "40px",
                }}
                disabled={!captchaValue}
              >
                {loader ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>
                    <span className="text-decoration-underline fs-2">
                      Let's go !
                    </span>

                    <div className="d-none d-md-block">
                      <img
                        src={arrowRight}
                        alt="arrowRight"
                        style={{
                          height: "40px",
                        }}
                      />
                    </div>
                  </>
                )}
              </button>
            </Form>
          )}
        </Formik>

        <p className="d-none d-md-block text-center fs-5 fw-bold">
          By submitting, you agree to our data use as outlined in our{" "}
          <span className="text-decoration-underline">Privacy Policy</span>.
        </p>
      </div>
    </div>
  );
};