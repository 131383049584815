import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAuth {
  email: string;
  country: string;
}

const initialState: IAuth = {
  email: "Alex.w@cornerswap.io",
  country: "pl",
};

type AuthEmail = PayloadAction<string>;
type Country = PayloadAction<string>;

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmail: (state, action: AuthEmail) => {
      state.email = action.payload;
    },
    setCountry: (state, action: Country) => {
      state.country = action.payload;
    },
  },
});

export const { setEmail, setCountry } = authSlice.actions;
export const authReducer = authSlice.reducer;